import React from "react";


const About = () => {
    return (
        <div className={"info-page"}>
            <p>
                Our team has been actively developing the project since 2023, the idea appeared in 2019. The platform
                will provide users with the opportunity to play a selected game with an element of chance for money.
                Each user will have an electronic balance, which can be replenished with a small commission, and each
                user will be able to withdraw their money in full. There is no casino element here, playing with real
                people for real money, which can be withdrawn in full.
            </p>
            <p>
                At the moment the project is running in a demo version and has one tic-tac-toe game available, and
                instead of real money, virtual currency will be used, which has no value. This is necessary for
                debugging and safe launch of the project, but you can register now and get acquainted with the project!
            </p>
            <p>
                In addition to the main functionality of working in your personal account and the game itself, we will
                develop the project in the direction of a convenient, pleasant interface, a selection of many games,
                additional functionality (chat for communication during the game), protection against new cyber threats
                and data leaks. We are open to your wishes and suggestions and will be grateful for criticism.
            </p>
            <p>
                The project is built on absolute trust and we will do everything in terms of the safety and reliability
                of your money. means to earn it. Have a nice time!
            </p>
            <hr/>
            <p>
                Наша команда ведет активную разработку проекта с 2023 года, идея появилась в 2019 году. Платформа
                предоставит пользователям возможность сыграть в выбраную игру с элементом случайности на деньги. У
                каждого пользователя будет электроный баланс, который можно будет пополнить с небольшой комисcией и
                каждый пользователь сможет в полном объеме вывести свои деньги. Здесь отсутсвует элемент казино, игра с
                реальными людьми на реальные деньги, которые можно вывести в полном объеме.
            </p>
            <p>
                В данный момент проект запущен в демо версии и имеет одну доступную игру крестики-нолики, и вместо
                настоящих
                денег будет использоваться виртуальная валюта, которая не имеет никакой ценности.
                Это необходимо для отладки и безопасного запуска проекта, но вы уже сейчас можете регистрироваться и
                знакомится с проектом!
            </p>
            <p>
                Помимо основного функционала работы в личном кабинете и непосредственно игры, мы будем развивать проект
                в направлении удобного приятного интерфейса, выбора множества игр, дополнительного функционала(чат для
                общения во время игры), защиты от новых киберугроз и утечки данных. Мы открыты к вашим пожеланиям и
                предложениям и будем благодарны за критику.
            </p>
            <p>
                Проект построен на абсолютном доверии и мы сделаем все в плане безопасности и надежности ваших денежных
                средств чтобы его заслужить.
                Приятного времяпровождения!
            </p>
        </div>
    );
};

export default About;
