import React, {useContext} from "react";

import Header from "./components/Header";
import XOGameList from "./components/XOGameList";
import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import XOGame from "./components/XOGame";
import Login from "./components/Login";
import Register from "./components/Register";
import {UserContext} from "./context/UserContext";
import Main from "./components/Main";
import CreateXOGame from "./components/CreateXOGame";
import Footer from "./components/Footer";
import About from "./components/About";
import Contacts from "./components/Contacts";

const PrivateRoutes = () => {
    const token = useContext(UserContext).token;
    if (token) {
        return <Outlet/>;
    }
    return <Navigate to="/login"/>;
};

const App = () => {
    return (
        <>
            <Header/>
            <div id={"content"}>
                <Routes>
                    <Route element={<PrivateRoutes/>}>
                        <Route path="/xo_game/:gameId" element={<XOGame/>}/>
                        <Route path="/xo_game_list" element={<XOGameList/>}/>
                        <Route path="/create_xo_game" element={<CreateXOGame/>}/>
                    </Route>
                    <Route path="/contacts" element={<Contacts/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/" element={<Main/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="*" element={<div>404</div>}/>
                </Routes>
            </div>
            <Footer/>
        </>
    );
};

export default App;
