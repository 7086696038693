import React from "react";
import App from "./App";
import {UserProvider} from "./context/UserContext";
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import {ModalProvider} from "./context/ModalContext";
import {GameIdProvider} from "./context/GameIdContext";
import {ModalProfileProvider} from "./context/ModalProfileContext";

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <BrowserRouter>
        <UserProvider>
            <ModalProvider>
                <ModalProfileProvider>
                    <GameIdProvider>
                        <App/>
                    </GameIdProvider>
                </ModalProfileProvider>
            </ModalProvider>
        </UserProvider>
    </BrowserRouter>
);