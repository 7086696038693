import React from "react";
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <div id={"footer"}>
            <div id={"footer-info"}>
                <div id={"footer-elem-container"}>
                    <Link className={"footer-elem"} to={"/"}>
                        Main
                    </Link>
                    <Link className={"footer-elem"} to={"/about"}>
                        About Us
                    </Link>
                    <Link className={"footer-elem"} to={"/contacts"}>
                        Contacts
                    </Link>
                </div>
            </div>
            <div id={"footer-copyright"}>© 2023-{new Date().getFullYear()} All Rights Reserved DURAK</div>
        </div>
    );
};

export default Footer;
