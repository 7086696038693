import React, {useContext} from "react";

import {UserContext} from "../context/UserContext";
import {GameIdContext} from "../context/GameIdContext";
import {ModalContext} from "../context/ModalContext";
import {ModalProfileContext} from "../context/ModalProfileContext";


const Logout = () => {
    const setToken = useContext(UserContext).setToken;
    const setUserId = useContext(UserContext).setUserId;
    const setGameId = useContext(GameIdContext).setGameId;
    const setModalActive = useContext(ModalContext).setModalActive;
    const setModalProfileActive = useContext(ModalProfileContext).setModalProfileActive;
    const handleLogout = () => {
        setToken(null);
        setUserId(null);
        setGameId(null);
        setModalActive(null);
        setModalProfileActive(null);
    };

    return (
        <>
            <span onClick={handleLogout}>
                Logout
            </span>
        </>
    );
};

export default Logout;
