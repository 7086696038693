import React from "react";


const Contacts = () => {
    return (
        <div className={"info-page"}>
            <p>
                We will soon register a working email, but in the meantime, if you have any questions, you can write to Telegram @rafaaslan
            </p>
            <hr/>
            <p>
                Вскоре мы зарегистрируем рабочую почту, а пока что по всем вопросам можете писать в Телеграм @rafaaslan
            </p>
        </div>
    );
};

export default Contacts;
