import React, {createContext, useState} from "react";

export const GameIdContext = createContext();

export const GameIdProvider = (props) => {
    const [gameId, setGameId] = useState(null);
    return (
        <GameIdContext.Provider value={{'gameId': gameId, 'setGameId': setGameId}}>
            {props.children}
        </GameIdContext.Provider>
    );
};