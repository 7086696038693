import React from "react";
import "./modal.css"


const Modal = ({active, setActive, children}) => {
    return (
        <div className={active ? "my-modal active" : "my-modal"} onClick={() => {
            setActive(false);
        }}>
            <span onClick={(e) => e.stopPropagation()}>
                {children}
            </span>

        </div>
    );
};

export default Modal;
