import React from "react";
import {Link} from "react-router-dom";
import logo1 from '../media/karts.png';


const Main = () => {
    return (
        <div id={"welcome-page"}>
            <div id="welcome-message">
                <h2>
                    Играйте, общайтесь
                    <br/>находите друзей и эмоции
                    <hr style={{border: "2px solid", width: "230px", borderRadius: "3px"}}/>
                    Play, chat
                    <br/>find friends and emotions
                </h2>
                <Link id={"xo-game-list-link"} to={"/xo_game_list"}>
                    <h3>List of XO Games</h3>
                </Link>
            </div>
            <div>
                <img className={"welcome-image"} src={logo1} alt="img"/>
            </div>

        </div>
    );
};

export default Main;
