import React, {useContext} from "react";

import {UserContext} from "../context/UserContext";
import {Link} from "react-router-dom";
import Modal from "./Modal";
import {ModalProfileContext} from "../context/ModalProfileContext";
import logo1 from "../media/modal-profile-image.svg";
import Logout from "./Logout";

const Header = () => {
    const token = useContext(UserContext).token;
    const modalProfileActive = useContext(ModalProfileContext).modalProfileActive;
    const setModalProfileActive = useContext(ModalProfileContext).setModalProfileActive;
    const profileReducer = (state, action) => {
        return action
    };
    const [profile, dispatchProfile] = React.useReducer(
        profileReducer,
        {}
    );
    const getProfile = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        };
        const response = await fetch("/api/auth/user/me", requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("something messed up");
        } else {
            dispatchProfile(data)
        }
    };

    return (
        <div id={"header"}>
            {!token ? (
                    <>
                        <Link to={"/login"}>
                            <button className={"button"} type="button">Login</button>
                        </Link>
                        <Link to={"/register"}>
                            <button className={"button"} type="button">Register</button>
                        </Link>
                    </>
            ) : (
                <div className={"profile"} title={'Your profile'} onClick={() => {
                    getProfile();
                    setModalProfileActive(true);
                }}>
                </div>
            )}
            <Modal active={modalProfileActive} setActive={setModalProfileActive}>
                <div className={modalProfileActive ? "modal-content-profile active" : "modal-content-profile"}>
                    <img id={"modal-profile-image"} src={logo1} alt="img"/>
                    <div id={"modal-profile-info"}>
                        <h4>{profile.name} {profile.surname}</h4>
                        <h4>{profile.email}</h4>
                        <h4>Balance {profile.balance} coins</h4>
                        <div id={"modal-profile-logout"}>
                            <div>
                                <Logout/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Header;
