import React, {useContext} from "react";
import './main.css'
import {UserContext} from "../context/UserContext";
import {Link, useParams} from "react-router-dom";
import Modal from "./Modal";
import {ModalContext} from "../context/ModalContext";

const XOGame = () => {
    const modalActive = useContext(ModalContext).modalActive;
    const setModalActive = useContext(ModalContext).setModalActive;
    let gameId = useParams().gameId;
    const userId = useContext(UserContext).userId;

    var host = window.location.hostname;
    var ws_protocol = window.location.protocol === "http:" ? "ws:" : "wss:";
    var ws = new WebSocket(`${ws_protocol}//${host}:8000/xo_game/ws/${gameId}/${userId}`,);
    let checks = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [6, 4, 2],
    ]

    function highlightAll() {
        for (let c = 0; c < 9; c++) {
            document.getElementById(String(c)).style.backgroundColor = 'gray';
        }
        setModalActive(_ => true)
    }

    function hightLightRow() {
        let cells = []
        for (let c = 0; c < 9; c++) {
            cells.push(document.getElementById(String(c)))
        }
        checks.forEach((row) => {
            if (cells[row[0]].innerHTML === cells[row[1]].innerHTML &&
                cells[row[0]].innerHTML === cells[row[2]].innerHTML &&
                cells[row[0]].innerHTML !== "*") {
                cells[row[0]].style.backgroundColor = 'green';
                cells[row[1]].style.backgroundColor = 'green';
                cells[row[2]].style.backgroundColor = 'green';
                setModalActive(_ => true)
            }
        });

    }

    function updateField(board_condition) {
        for (let i = 0; i < 9; i++) {
            var cell = document.getElementById(String(i))
            cell.innerHTML = board_condition[i]
        }
    }

    function updateInfo(message) {
        var info = document.getElementById('info')
        info.innerHTML = message
    }

    function updateCurrentPlayer(playerSymbol) {
        var currentPlayer = document.getElementById('current-player')
        currentPlayer.innerHTML = playerSymbol
    }
    function cellClick(id) {
        ws.send(JSON.stringify({cell: id}))
    }

    function connect() {
        var ws = new WebSocket(`${ws_protocol}//${host}:8000/xo_game/ws/${gameId}/${userId}`,);
        ws.onmessage = function (e) {
            var response = JSON.parse(e.data)
            if (response.board_condition) {
                updateField(response.board_condition)
            }
            if (response.message) {
                updateInfo(response.message);
                if (response.message === 'draw') {
                    highlightAll();
                } else {
                    hightLightRow();
                }
            }
            if (response.current_player) {
                updateCurrentPlayer(response.current_player);
            }
        }
        ws.onclose = function (e) {
            if (e.code !== 1000) {
                updateInfo(e.reason);
            }
        }
    }

    connect()

    return (
        <>
            <div id="game-field">
                <div id={"game-info"}>
                    <div>
                        <p>XO Durak Game</p>
                    </div>
                    <div>
                        <p>Current player: <span id="current-player"></span></p>
                        <p>Info: <span id="info"></span></p>
                    </div>
                </div>
                <div id={"board-capsule"}>
                    <table id="board">
                            <tr>
                                <td id="0" onClick={() => cellClick(0)}>*</td>
                                <td id="1" onClick={() => cellClick(1)}>*</td>
                                <td id="2" onClick={() => cellClick(2)}>*</td>
                            </tr>
                            <tr>
                                <td id="3" onClick={() => cellClick(3)}>*</td>
                                <td id="4" onClick={() => cellClick(4)}>*</td>
                                <td id="5" onClick={() => cellClick(5)}>*</td>
                            </tr>
                            <tr>
                                <td id="6" onClick={() => cellClick(6)}>*</td>
                                <td id="7" onClick={() => cellClick(7)}>*</td>
                                <td id="8" onClick={() => cellClick(8)}>*</td>
                            </tr>
                        </table>
                    </div>
            </div>
            <Modal active={modalActive} setActive={setModalActive}>
                <div className={modalActive ? "modal-content active" : "modal-content"}>
                    <p>Game is finished!</p>
                    <Link to={"/"}>
                        <button type="button" onClick={() =>
                            setModalActive(_ => false)}>Return to main page</button>
                    </Link>
                </div>
            </Modal>
        </>
);
};

export default XOGame;
