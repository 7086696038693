import React, {useContext, useState} from "react";

import {UserContext} from "../context/UserContext";
import {useNavigate} from "react-router-dom";
import ErrorMessage from "./ErrorMessage";

const CreateXOGame = () => {
    const [xoGameName, setxoGameName] = useState("");
    const [bet, setBet] = useState("");
    const token = useContext(UserContext).token;
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");

    const submitCreateXOGame = async () => {
        const requestOptions = {
            method: "POST",
            withCredentials: true,
            referrerPolicy: "strict-origin-when-cross-origin",
            mode: "cors",
            credentials: "include",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            }),
            body: JSON.stringify({name: xoGameName, bet_for_player: bet})
        };
        const response = await fetch('/api/xo_game', requestOptions);
        if (!response.ok) {
            setErrorMessage((await response.json()).detail);
        } else {
            navigate("/xo_game_list")
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        submitCreateXOGame();
    };

    return (
        <form className={"form"} onSubmit={handleSubmit}>
            <div className={"form-content"}>
                <div className={"input-atom"}>
                    <label>Game name</label>
                    <input
                        type="text"
                        placeholder="Enter xo game name"
                        value={xoGameName}
                        onChange={(e) => setxoGameName(e.target.value)}
                        required
                    />
                </div>
                <div className={"input-atom"}>
                    <label>Bet for play</label>
                    <input
                        type="number"
                        min={50}
                        placeholder="Enter bet for xo game"
                        value={bet}
                        onChange={(e) => setBet(e.target.value)}
                        required
                    />
                </div>
                <ErrorMessage message={errorMessage}/>
                <button className={"input-form-button"} type="submit">
                    Create XO Game
                </button>
            </div>
        </form>
    );
};

export default CreateXOGame;
