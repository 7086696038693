import React, {createContext, useEffect, useState} from "react";

export const UserContext = createContext();

export const UserProvider = (props) => {
    const [token, setToken] = useState(localStorage.getItem("awesomeLeadsToken"));
    const [userId, setUserId] = useState(localStorage.getItem("user_id"));
    useEffect(() => {

        const fetchUser = async () => {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            };

            const response = await fetch("/api/auth/user/me", requestOptions);

            if (!response.ok) {
                setToken(null);
            }
            localStorage.setItem("awesomeLeadsToken", token);
            localStorage.setItem("user_id", userId);
        };
        fetchUser();
    }, [token, userId]);

    return (
        <UserContext.Provider value={{'token': token, 'setToken': setToken, 'userId': userId, 'setUserId': setUserId}}>
            {props.children}
        </UserContext.Provider>
    );
};