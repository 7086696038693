import React, {createContext, useState} from "react";

export const ModalContext = createContext();

export const ModalProvider = (props) => {
    const [modalActive, setModalActive] = useState(false);
    return (
        <ModalContext.Provider value={{'modalActive': modalActive, 'setModalActive': setModalActive}}>
            {props.children}
        </ModalContext.Provider>
    );
};