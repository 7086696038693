import React, {createContext, useState} from "react";

export const ModalProfileContext = createContext();

export const ModalProfileProvider = (props) => {
    const [modalProfileActive, setModalProfileActive] = useState(false);
    return (
        <ModalProfileContext.Provider
            value={{'modalProfileActive': modalProfileActive, 'setModalProfileActive': setModalProfileActive}}>
            {props.children}
        </ModalProfileContext.Provider>
    );
};